<template>
  <div>
    <div class="card">
      <h2>Заказать индивидуальный дизайн</h2>
      <p>
        Доверьте разработку дизайна настоящим профессионалам. Заполните
        контактную форму, чтобы мы смогли связаться с вами для дальнейшего
        сотрудничества.
      </p>
      <button
        class="btn btnMaim btnMaim_nona"
        @click="goTo({ name: 'create-order' }, 'individual')"
      >
        Заказать
      </button>
    </div>
    <div class="card">
      <h2>Создать свой дизайн в конструкторе</h2>
      <img :src="imgToConstruct" alt="" />
      <button
        class="btn btnMaim btnMaim_wide"
        @click="goTo({ name: 'constructor-car' }, 'construct')"
      >
        Конструктор<i class="far fa-long-arrow-right"></i>
      </button>
    </div>
    <div class="card">
      <h2>У меня есть готовый дизайн</h2>
      <p>
        Вам потребуется просто загрузить превью с вашим дизайном и заполнить
        контактную информацию, чтобы мы смогли связаться с вами для дальнейшего
        сотрудничества.
      </p>
      <button
        class="btn btnMaim btnMaim_nona"
        @click="goTo({ name: 'create-order' }, 'your')"
      >
        Заказать
      </button>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields(["settings", "design"]),
    imgToConstruct() {
      return this.settings.imgs.toConstruct;
    },
  },
  methods: {
    goTo(to, design) {
      this.$router.push(to).catch((err) => {
        throw new Error(`Route from choice-design.index: ${err}.`);
      });
      this.design = design;
    },
  },
};
</script>
