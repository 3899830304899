<template>
  <div class="constructor_choice">
    <Top
      :prev="{ text: 'К выбору автомобиля', name: 'index' }"
      :state="{ value: 2, text: 'Дизайн' }"
    />
    <Cards class="cards" />
    <Bottom class="bottom" />
  </div>
</template>

<script>
import Top from "../__top__";
import Bottom from "../__bottom__";
import Cards from "./__cards__";

export default {
  components: {
    Top,
    Bottom,
    Cards,
  },
};
</script>
